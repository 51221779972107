import LeadsIcon from '@mui/icons-material/Groups2'
import InfoIcon from '@mui/icons-material/Info'
import LimitsIcon from '@mui/icons-material/LockClock'
import DocumentationIcon from '@mui/icons-material/TextSnippet'
import { type RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'
import { DetailsLayout } from '@/layouts'

export const channelsRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.channels,
    async () => await import('@/pages/publisherManagement/ChannelsPage'),
  ),
  {
    element: (
      <DetailsLayout
        tabs={[
          {
            label: 'General',
            icon: <InfoIcon />,
            linkTo: routes.channelGeneral.path,
          },
          {
            label: 'Limits',
            icon: <LimitsIcon />,
            linkTo: routes.channelLimits.path,
          },
          {
            label: 'Integration',
            icon: <DocumentationIcon />,
            linkTo: routes.channelIntegration.path,
          },
          {
            label: 'Leads',
            icon: <LeadsIcon />,
            linkTo: routes.channelLeads.path,
          },
        ]}
      />
    ),
    children: [
      createRoleProtectedRouteObject(
        routes.channelGeneral,
        async () =>
          await import('@/pages/publisherManagement/ChannelGeneralPage'),
      ),
      createRoleProtectedRouteObject(
        routes.channelLimits,
        async () =>
          await import('@/pages/publisherManagement/ChannelLimitsPage'),
      ),
      createRoleProtectedRouteObject(
        routes.channelIntegration,
        async () =>
          await import('@/pages/publisherManagement/ChannelIntegrationPage'),
      ),
      createRoleProtectedRouteObject(
        routes.channelLeads,
        async () =>
          await import('@/pages/publisherManagement/ChannelLeadsPage'),
      ),
    ],
  },
]
