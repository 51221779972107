import InfoIcon from '@mui/icons-material/Info'
import { type RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'
import { DetailsLayout } from '@/layouts'

export const buyersRoutes: RouteObject[] = [
  {
    element: (
      <DetailsLayout
        tabs={[
          {
            label: 'General',
            icon: <InfoIcon />,
            linkTo: routes.buyersGeneral.path,
          },
        ]}
      />
    ),
    children: [
      createRoleProtectedRouteObject(
        routes.buyersGeneral,
        async () => await import('@/pages/buyerManagement/BuyersGeneralPage'),
      ),
    ],
  },
]
