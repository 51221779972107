import { type UserRole } from '@/providers/AuthenticationProvider/types'

export interface RouteData {
  path: string
  name: string
  allowedRoles: UserRole[] | undefined
}

type Routes = Record<string, RouteData>

/**
 * An object to hold all the paths used in the router so we can
 * take advantage of TypeScript autocomplete to make sure we get
 * the right path. The name property is used by multiple components:
 *  - The Breadcrumbs for the displayed text of each crumb
 *  - The Page Header that is automatically displayed for each page
 *  - The Side Menu for displayed for the menu items on it
 */
export const routes = {
  // Main menu root items
  dashboard: {
    path: '/dashboard',
    name: 'Dashboard',
    allowedRoles: undefined,
  },
  newBusinesses: {
    path: '/new-businesses',
    name: 'New Businesses',
    allowedRoles: ['admin'],
  },
  inForcePolicies: {
    path: '/in-force-policies',
    name: 'In Force Policies',
    allowedRoles: ['admin'],
  },
  administration: {
    path: '/administration',
    name: 'Administration',
    allowedRoles: ['admin'],
  },
  integrations: {
    path: '/integrations',
    name: 'Integrations',
    allowedRoles: ['admin'],
  },

  // Main menu Reports nested items
  inForceReport: {
    path: '/in-force-report',
    name: 'In Force Report',
    allowedRoles: ['admin'],
  },
  pendingReport: {
    path: '/pending-report',
    name: 'Pending Report',
    allowedRoles: ['admin'],
  },
  submittedReport: {
    path: '/submitted-report',
    name: 'Submitted Report',
    allowedRoles: ['admin'],
  },
  earningsReport: {
    path: '/earnings-report',
    name: 'Earnings Report',
    allowedRoles: ['admin'],
  },
  commissionsReport: {
    path: '/commissions-report',
    name: 'Commissions Report',
    allowedRoles: ['admin'],
  },
  comparisonReport: {
    path: '/comparison-report',
    name: 'Comparison Report',
    allowedRoles: ['admin'],
  },
  hierarchy: {
    path: '/hierarchy',
    name: 'Hierarchy',
    allowedRoles: ['admin'],
  },
  publisherReport: {
    path: '/publisher-summary',
    name: 'Publisher Summary',
    allowedRoles: ['admin'],
  },
  leadDetails: {
    path: '/lead-details',
    name: 'Lead Details',
    allowedRoles: ['admin', 'agencyAdmin', 'agent'],
  },

  // Main menu Contacts nested items
  agencies: {
    path: '/agencies',
    name: 'Agencies',
    allowedRoles: undefined,
  },
  agencyGeneral: {
    path: '/agencies/:agencyId/general',
    name: 'Agency Details',
    allowedRoles: undefined,
  },
  agencyCommissions: {
    path: '/agencies/:agencyId/commissions',
    name: 'Agency Commissions',
    allowedRoles: undefined,
  },
  agencyCommissionGeneral: {
    path: '/agencies/:agencyId/commissions/:commissionId/general',
    name: 'Agency Commission Details',
    allowedRoles: undefined,
  },
  agencyContracting: {
    path: '/agencies/:agencyId/contracting',
    name: 'Agency Contracting',
    allowedRoles: undefined,
  },
  agents: {
    path: '/agents',
    name: 'Agents',
    allowedRoles: undefined,
  },
  agentGeneral: {
    path: '/agents/:agentId/general',
    name: 'Agent Details',
    allowedRoles: undefined,
  },
  agentCommissions: {
    path: '/agents/:agentId/commissions',
    name: 'Agent Commissions',
    allowedRoles: undefined,
  },
  agentCommissionGeneral: {
    path: '/agents/:agentId/commissions/:commissionId/general',
    name: 'Agent Commission Details',
    allowedRoles: undefined,
  },
  agentContracting: {
    path: '/agents/:agentId/contracting',
    name: 'Agent Contracting',
    allowedRoles: undefined,
  },
  customers: {
    path: '/customers',
    name: 'Customers',
    allowedRoles: undefined,
  },
  customerDetails: {
    path: '/customers/:id',
    name: 'Customer Details',
    allowedRoles: undefined,
  },
  invitations: {
    path: '/invitations',
    name: 'Invitations',
    allowedRoles: undefined,
  },
  acceptInvite: {
    path: '/invite/:token',
    name: 'Accept Invite',
    allowedRoles: undefined,
  },

  // Main menu Calendar nested items
  myCalendar: {
    path: '/calendar',
    name: 'My Calendar',
    allowedRoles: ['admin'],
  },
  tasks: {
    path: '/tasks',
    name: 'Tasks',
    allowedRoles: ['admin'],
  },
  reminders: {
    path: '/reminders',
    name: 'Reminders',
    allowedRoles: ['admin'],
  },

  // Main menu Sales nested items
  prospects: {
    path: '/prospects',
    name: 'Prospects',
    allowedRoles: ['admin'],
  },
  pipeline: {
    path: '/pipeline',
    name: 'Pipeline',
    allowedRoles: ['admin'],
  },
  quotes: {
    path: '/quotes',
    name: 'Quotes',
    allowedRoles: ['admin'],
  },
  applications: {
    path: '/applications',
    name: 'Applications',
    allowedRoles: ['admin'],
  },

  // Main menu Marketing nested items
  marketingCampaigns: {
    path: '/marketing-campaigns',
    name: 'Marketing Campaigns',
    allowedRoles: ['admin'],
  },
  website: {
    path: '/website',
    name: 'Website',
    allowedRoles: ['admin'],
  },
  marketingContacts: {
    path: '/marketing-contacts',
    name: 'Contacts',
    allowedRoles: ['admin'],
  },
  emailTemplates: {
    path: '/email-templates',
    name: 'Email Templates',
    allowedRoles: ['admin'],
  },

  // Main menu Lead Management nested items
  leadCampaigns: {
    path: '/lead-campaigns',
    name: 'Lead Campaigns',
    allowedRoles: undefined,
  },
  leadCampaignGeneral: {
    path: '/lead-campaigns/:campaignId/general',
    name: 'Campaign Details',
    allowedRoles: undefined,
  },
  leadCampaignFilters: {
    path: '/lead-campaigns/:campaignId/filters',
    name: 'Campaign Filters',
    allowedRoles: undefined,
  },

  // Main menu Publisher Management nested items
  publishers: {
    path: '/publishers',
    name: 'Publishers',
    allowedRoles: ['admin'],
  },
  publisherGeneral: {
    path: '/publishers/:publisherId/general',
    name: 'Publisher Details',
    allowedRoles: ['admin'],
  },
  publisherRevShare: {
    path: '/publishers/:publisherId/rev-share',
    name: 'Publisher Revenue Share',
    allowedRoles: ['admin'],
  },
  publisherAccounting: {
    path: '/publishers/:publisherId/accounting',
    name: 'Publisher Accounting',
    allowedRoles: ['admin'],
  },
  channels: {
    path: '/channels',
    name: 'Channels',
    allowedRoles: ['admin'],
  },
  channelGeneral: {
    path: '/channels/:channelId/general',
    name: 'Channel Details',
    allowedRoles: ['admin'],
  },
  channelLimits: {
    path: '/channels/:channelId/limits',
    name: 'Channel Limits',
    allowedRoles: ['admin'],
  },
  channelIntegration: {
    path: '/channels/:channelId/integration',
    name: 'Channel Integration',
    allowedRoles: ['admin'],
  },
  channelLeads: {
    path: '/channels/:channelId/leads',
    name: 'Channel Leads',
    allowedRoles: ['admin'],
  },

  // Main menu Buyer Management nested items
  buyersGeneral: {
    path: '/buyers',
    name: 'Buyers Settings',
    allowedRoles: ['admin'],
  },

  // Main menu System Management nested items
  users: {
    path: '/users',
    name: 'Users',
    allowedRoles: ['admin'],
  },
  carriers: {
    path: '/carriers',
    name: 'Carriers',
    allowedRoles: ['admin'],
  },
  carrierGeneral: {
    path: '/carriers/:carrierId/general',
    name: 'Carrier Details',
    allowedRoles: ['admin'],
  },
  carrierProducts: {
    path: '/carriers/:carrierId/products',
    name: 'Carrier Products',
    allowedRoles: ['admin'],
  },
  productGeneral: {
    path: '/carriers/:carrierId/products/:productId/general',
    name: 'Product Details',
    allowedRoles: ['admin'],
  },
  permissions: {
    path: '/permissions',
    name: 'Permissions',
    allowedRoles: ['admin'],
  },
  pendingUsers: {
    path: '/pending-users',
    name: 'Pending Users',
    allowedRoles: ['admin'],
  },
  commissions: {
    path: '/commissions',
    name: 'Commissions',
    allowedRoles: ['admin'],
  },

  // Authentication
  signIn: {
    path: '/sign-in',
    name: 'Sign In',
    allowedRoles: undefined,
  },
  signUp: {
    path: '/sign-up',
    name: 'Sign Up',
    allowedRoles: undefined,
  },
  forgotPassword: {
    path: '/forgot-password',
    name: 'Forgot Password',
    allowedRoles: undefined,
  },
  createProfile: {
    path: '/create-profile',
    name: 'Create Profile',
    allowedRoles: undefined,
  },
  applicationPending: {
    path: '/pending',
    name: 'Application Pending',
    allowedRoles: undefined,
  },

  // Account settings
  accountSecurity: {
    path: '/account/security',
    name: 'Account Security',
    allowedRoles: undefined,
  },
  accountCreditHistory: {
    path: '/account/credit-history',
    name: 'Credit History',
    allowedRoles: undefined,
  },

  // Payment
  addCredits: {
    path: '/add-credits',
    name: 'Add Credits',
    allowedRoles: undefined,
  },
  orderConfirmation: {
    path: '/order-confirmation',
    name: 'Order Confirmation',
    allowedRoles: undefined,
  },

  // Other
  termsAndConditions: {
    path: '/terms-and-conditions',
    name: 'Terms and Conditions',
    allowedRoles: undefined,
  },
  privacyPolicy: {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    allowedRoles: undefined,
  },
} as const satisfies Routes
